

































































































import { Component, Mixins } from 'vue-property-decorator';
import { ReportChartRingComponent, ReportChartHistogramComponent } from '@/mixins/report-chart-base';
import CustomerFlowIndexService from './service/customer-flow';
import { BAFacilityRunTimeQueryModel } from './model/ba-facility-run-time-model';
import { BAFacilityFaultQueryModel } from './model/ba-facility-fault-model';
import { CustomerFlowLocationQueryModel } from './model/customer-flow-location';
import { CustomerFlowTrendQueryModel } from './model/customer-flow-trend';

@Component
export default class CustomerFlowIndexComponent extends Mixins(ReportChartRingComponent, ReportChartHistogramComponent) {
    headerCardList: Array<any> = [
        { title: '留存客流', count: null, unit: '人', loading: true, icon: '/img/customer-retained.png' },
        { title: '一小时累计流入', count: null, unit: '人次', loading: true, icon: '/img/customer-in.png' },
        { title: '一小时累计流出', count: null, unit: '人次', loading: true, icon: '/img/customer-out.png' },
        { title: '今天累计客流', count: null, unit: '人次', loading: true, icon: '/img/customer-day.png' },
        { title: '本月累计客流', count: null, unit: '人次', loading: true, icon: '/img/customer-month.png' }
    ];

    dayInLocationCount: any = null;
    dayInLocationCountLoading: boolean = true;

    dayRetainedLocationCount: any = null;
    dayRetainedLocationCountLoading: boolean = true;

    dayTrendCount: any = null;
    dayTrendCountLoading: boolean = true;

    weekTrendCount: any = {};
    weekTrendCountLoading: boolean = true;

    facilityRunTimeCount: any = {};
    facilityRunTimeCountLoading: boolean = true;

    waterPressData: any = null;
    waterPressDataLoading: boolean = false;

    facilityStatusData: any = null;
    facilityStatusDataLoading: boolean = true;

    facilityFaultData: any = {};
    facilityFaultDataLoading: boolean = true;

    alarmStatusCountData: any = null;
    alarmStatusCountDataLoading: boolean = true;

    last7DayAlarmData: any = {};
    last7DayAlarmDataLoading: boolean = true;

    alarmGradeCountData: any = null;
    alarmGradeCountDataLoading: boolean = true;

    last7DayTop5AlarmData: any = null;
    last7DayTop5AlarmDataLoading: boolean = true;

    created() {
        this.initData();
    }

    initData() {
        CustomerFlowIndexService.getRetainedFlowCount().then(res => {
            this.headerCardList[0].count = res;
        }).finally(() => {
            this.headerCardList[0].loading = false;
        });

        CustomerFlowIndexService.gethourInOutFlowCount().then(res => {
            this.headerCardList[1].count = _.get(res, 'inCount');
            this.headerCardList[2].count = _.get(res, 'outCount');
        }).finally(() => {
            this.headerCardList[1].loading = false;
            this.headerCardList[2].loading = false;
        });

        CustomerFlowIndexService.getDayOfInFlowCount().then(res => {
            this.headerCardList[3].count = res;
        }).finally(() => {
            this.headerCardList[3].loading = false;
        });

        CustomerFlowIndexService.getMonthOfInFlowCount().then(res => {
            this.headerCardList[4].count = res;
        }).finally(() => {
            this.headerCardList[4].loading = false;
        });

        CustomerFlowIndexService.getLocationCount(new CustomerFlowLocationQueryModel('RETAINEDFLOW').toService()).then(res => {
            this.dayRetainedLocationCount = res;
        }).finally(() => this.dayRetainedLocationCountLoading = false);

        CustomerFlowIndexService.getLocationCount(new CustomerFlowLocationQueryModel('INFLOW').toService()).then(res => {
            this.dayInLocationCount = res;
        }).finally(() => this.dayInLocationCountLoading = false);

        CustomerFlowIndexService.getTrendCount(new CustomerFlowTrendQueryModel(['INFLOW']).toService()).then(res => {
            this.dayTrendCount = res;
        }).finally(() => this.dayTrendCountLoading = false);

        CustomerFlowIndexService.getTrendCount(new CustomerFlowTrendQueryModel(['INFLOW'], true).toService()).then(res => {
            this.weekTrendCount = res;
        }).finally(() => this.weekTrendCountLoading = false);

        CustomerFlowIndexService.getLast7DayTop5AlarmCount({ limit: 5 }).then(res => {
            this.last7DayTop5AlarmData = res;
        }).finally(() => this.last7DayTop5AlarmDataLoading = false);

        CustomerFlowIndexService.getAlarmStatusCount().then(res => {
            this.alarmStatusCountData = res;
        }).finally(() => this.alarmStatusCountDataLoading = false);

        CustomerFlowIndexService.getLast7DayAlarmCount().then(res => {
            this.last7DayAlarmData = res;
        }).finally(() => this.last7DayAlarmDataLoading = false);

        CustomerFlowIndexService.getAlarmGradeCount().then(res => {
            this.alarmGradeCountData = res;
        }).finally(() => this.alarmGradeCountDataLoading = false);
    }

    get ParkingGateRankYAxis() {
        return {
            type: 'category',
            data: _.map(_.get(this.last7DayTop5AlarmData, 'rows'), item => item.name),
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            splitLine: {
                show: false
            }
        };
    }
}
